// 语言配置
import { ref, computed, getCurrentInstance } from "vue";

export function useOrder() {
  const vm = getCurrentInstance();
  const statusList = [
    vm.proxy.$t("order.buyIng"),
    vm.proxy.$t("order.closing"),
    vm.proxy.$t("order.hasClosed"),
  ];
  const getStatusName = (status) => statusList[status - 1];
  return {
    getStatusName,
  };
}
