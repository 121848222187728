<template>
  <div class="container">
    <h3>{{ $t("market.historyOrders") }}</h3>
    <div class="tabsBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('market.digitalCurrency')"
          name="1"
        ></el-tab-pane>
        <el-tab-pane :label="$t('market.nobleMetal')" name="6"></el-tab-pane>
        <el-tab-pane
          :label="$t('market.foreignExchange')"
          name="5"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :empty-text="$t('rankText.emptyText')"
    >
      <!-- 名称 -->
      <el-table-column prop="name" :label="$t('rankText.name')" align="center">
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ scope.row.symbol_name }}</span>
          </template>
          <template v-else>
            <span>{{ scope.row.product_name }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 数量 -->
      <el-table-column prop="num" align="center" :label="$t('market.volume')">
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ Number(scope.row.number).toFixed(2) }}</span>
          </template>
          <template v-else>
            <span>{{ Number(scope.row.number).toFixed(2) }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 购买价 -->
      <el-table-column prop="num" align="center" :label="$t('market.buyPrice')">
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ Number(scope.row.open_price).toFixed(5) }}</span>
          </template>
          <template v-else>
            <span>{{ Number(scope.row.open_price).toFixed(5) }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 成交价 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.dealPrice')"
      >
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ Number(scope.row.end_price).toFixed(5) }}</span>
          </template>
          <template v-else>
            <span>{{ Number(scope.row.end_price).toFixed(5) }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 手续费 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.serviceCharge')"
      >
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ Number(scope.row.fee).toFixed(5) }}</span>
          </template>
          <template v-else>
            <span>{{ Number(scope.row.fee).toFixed(5) }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 盈亏 -->
      <el-table-column
        prop="num"
        align="center"
        :label="$t('market.profitLoss')"
      >
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span :class="scope.row.fact_profits >= 0 ? 'red' : 'green'">{{
              Number(scope.row.fact_profits).toFixed(5)
            }}</span>
          </template>
          <template v-else>
            <span :class="scope.row.fact_profits >= 0 ? 'red' : 'green'">{{
              Number(scope.row.fact_profits).toFixed(5)
            }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 状态 -->
      <el-table-column prop="num" align="center" :label="$t('market.status')">
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span class="buyStatusSpan" :class="scope.row.type == '1' ? 'redBg' : 'greenBg'">{{
              scope.row.type == "1"
                ? $t("market.buyMore")
                : $t("market.buyEmpty")
            }}</span>
            <span>{{ getStatusName(scope.row.status) }}</span>
          </template>
          <template v-else>
            <span class="buyStatusSpan" :class="scope.row.type == '1' ? 'redBg' : 'greenBg'">{{
              scope.row.type == "1"
                ? $t("market.buyMore")
                : $t("market.buyEmpty")
            }}</span>
            <span>{{ getStatusName(scope.row.status) }}</span>
          </template>
        </template>
      </el-table-column>
      <!-- 时间 -->
      <el-table-column prop="num" align="center" :label="$t('market.time')">
        <template slot-scope="scope">
          <template v-if="activeName == '1'">
            <span>{{ scope.row.show_times_text }}</span>
          </template>
          <template v-else>
            <span>{{ scope.row.created_at }}</span>
          </template></template
        >
      </el-table-column>
    </el-table>
    <div class="pagContainer">
      <el-pagination
        background
        @current-change="initData"
        :current-page.sync="postData.page"
        :page-size="postData.limit"
        layout="total, prev, pager, next"
        :total="postData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getNumLists, getwhlistsTable } from "@/api/market";
import { useOrder } from "@/hooks/useOrder";
const { getStatusName } = useOrder();
const activeName = ref("1");
const handleClick = () => {
  postData.value.page = 1;
  postData.value.limit = 10;
  postData.value.total = 0;
  tableData.value = [];
  initData();
};
const postData = ref({
  page: 1,
  limit: 10,
  total: 0,
});
const tableData = ref([]);
const initData = async () => {
  let res = {};
  tableData.value = [];
  // 数字货币
  if (activeName.value == "1") {
    const { message } = await getNumLists({
      status: "-1",
      page: postData.value.page,
      limit: postData.value.limit,
    });
    if (message.data.length > 0) {
      const offsetInMinutes = new Date().getTimezoneOffset();
      message.data.forEach((item) => {
        let replace_time = item.created_at.replace(/\-/g, "/");
        let created_times = new Date(replace_time).getTime() / 1000;
        let gj_times = created_times + 4 * 60 * 60;
        let show_times = gj_times - offsetInMinutes * 60;
        let gj_times_text = trimTime(gj_times);
        let show_times_text = trimTime(show_times);
        item.show_times_text = show_times_text;
        item.offsetInMinutes = offsetInMinutes;
        item.gj_times_text = gj_times_text;
      });
    }
    res = message;
  } else {
    const { message } = await getwhlistsTable({
      cid: activeName.value,
      page: postData.value.page,
      limit: postData.value.limit,
    });
    res = message;
  }
  postData.value.total = res.total;
  tableData.value = res.data;
};
const trimTime = (times) => {
  var val = new Date(parseInt(times) * 1000);
  const check = (time) => (time < 10 ? `0${time}` : time);
  return `${val.getFullYear()}-${check(val.getMonth() + 1)}-${check(
    val.getDate()
  )} ${check(val.getHours())}:${check(val.getMinutes())}:${check(
    val.getSeconds()
  )}`;
};
onMounted(() => {
  initData();
});
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 35px auto;
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .tabsBox {
    background-color: $section-color;
    padding: 10px 0 0px 20px;
    box-sizing: border-box;
  }
  .buyStatusSpan {
    padding: 5px;
    border-radius: 5px;
    margin-right: 5px;
  }
  .pagContainer {
    padding: 10px 10px 10px 50px;
    background-color: $section-color;
  }
}
</style>
